import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Row, Col } from 'react-simple-flex-grid'
import Fade from 'react-reveal/Fade'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import Img from 'gatsby-image'
import classNames from 'classnames'
import { Parallax } from 'react-parallax'

import { AppContext } from '_context'
import { Layout } from '_templates'
import { SEO, ViewportObserver } from '_organisms'
import { Container, Text, ScrollArrow, Heading, Image } from '_atoms'
import { ColorRibbon, ProudCases } from '_molecules'
import exmoxLogo from '_images/pages/portfolio/exmox/logo.png'
import variables from '_config/css-variables'
import { useWindowSize } from '_hooks'

import styles from './styles.module.css'

const ExMoxCaseStudy = ({ data }) => {
  const content = data.contentfulExMoxCaseStudy

  const { setNavbarColor, setNavbarLight, setCustomBrandColor } = useContext(AppContext)

  const [width] = useWindowSize()
  const isColumnLayout = width < 992

  const [y, setY] = useState(0)

  useScrollPosition(
    ({ currPos }) => {
      setY(currPos.y * -1)
    },
    [],
    null,
    false,
    300
  )

  useEffect(() => {
    setNavbarColor('transparent')
    setNavbarLight(isColumnLayout)
    setCustomBrandColor('exmoxPrimary')
  }, [setNavbarColor, setNavbarLight, setCustomBrandColor, isColumnLayout])

  const visualSectionColors = [
    variables['exmox-primary-dark'],
    variables['exmox-secondary'],
    variables['exmox-secondary-light'],
    variables['color-white'],
  ]

  const visualSectionTextColors = ['white', 'dark', 'dark', 'dark']

  const getParallaxPercentage = (index, percentage) => {
    const parallaxMovementStrength = 250
    const distanceFromStartingPosition = parallaxMovementStrength * percentage
    const maximumBottomDeviceTopDistance = 160
    const maximumBottomDeviceBottomDistance = 240
    const maximumTopDeviceTopDistance = 80

    // The top and bottom devices position values are calculated to ensure
    // that when scrolling up/down, the top one doesn't go too far upwards,
    // and the bottom one doesn't go too low or too high
    const topDeviceBottomValue =
      distanceFromStartingPosition < maximumTopDeviceTopDistance
        ? distanceFromStartingPosition
        : maximumTopDeviceTopDistance

    const bottomDeviceBottomDistance =
      distanceFromStartingPosition < maximumBottomDeviceBottomDistance
        ? distanceFromStartingPosition
        : maximumBottomDeviceBottomDistance

    const bottomDeviceTopValue =
      distanceFromStartingPosition > maximumBottomDeviceTopDistance
        ? bottomDeviceBottomDistance
        : maximumBottomDeviceTopDistance

    switch (index) {
      case 0:
        return { top: bottomDeviceTopValue }
      case 1:
        return { top: 0 }
      case 2:
        return { bottom: topDeviceBottomValue }
      default:
        return { top: distanceFromStartingPosition }
    }
  }

  return (
    <Layout className={styles.exMoxCase} navbarColorOverride="transparent">
      <SEO
        title={content.pageTitle || 'Web &amp; Mobile App Design and Development Company'}
        description={
          content.pageDescription || 'Web &amp; Mobile App Design and Development Company'
        }
        thumbnail={content.pageThumbnail.file.url}
      />

      <section className={styles.hero}>
        <Container>
          <Row className={styles.heroRow}>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Fade distance="20" bottom>
                <Image alt="Exmox logo" src={exmoxLogo} className={styles.heroLogo} />
              </Fade>

              <Fade distance="20" delay={200} bottom>
                <Text size="28" className={styles.heroDescription} splitParagraphs>
                  {content.heroDescription.internal.content}
                </Text>
              </Fade>
            </Col>

            <Col xs={12} sm={12} md={6} lg={6}>
              <div className={styles.heroImages}>
                {content.heroImages.map((image, index) => (
                  <Fade distance="10%" delay={(index + 1) * 300} bottom key={image.description}>
                    <Img
                      fluid={image.fluid}
                      alt={image.description}
                      imgStyle={{ position: 'relative', objectFit: 'contain' }}
                      fadeIn={false}
                      className={styles.heroImagesImage}
                    />
                  </Fade>
                ))}
              </div>
            </Col>
          </Row>
          <Fade distance="20" delay={350} bottom>
            <ScrollArrow className={styles.heroArrowButton} isButton scrollId="challenge" />
          </Fade>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor('transparent')
            setNavbarLight(isColumnLayout)
            setCustomBrandColor('exmoxPrimary')
          }}
        />
      </section>

      <section className={styles.challenge} id="challenge">
        <Container>
          <Row>
            <Col xs={12} sm={12} md={3} lg={3}>
              <Fade distance="20" bottom>
                <Heading
                  type="h1"
                  bold
                  color="white"
                  className={classNames(styles.heading, styles.challengeHeading)}
                >
                  {content.challengeTitle}
                  <span className={styles.dot}>.</span>
                </Heading>
              </Fade>
            </Col>

            <Col xs={12} sm={12} md={9} lg={9}>
              <Fade distance="20" delay={200} bottom>
                <Text
                  size="22"
                  color="white"
                  className={classNames(styles.description, styles.challengeDescription)}
                  splitParagraphs
                >
                  {content.challengeDescription.internal.content}
                </Text>
              </Fade>
            </Col>
          </Row>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['exmox-primary-dark'])
            setNavbarLight(false)
            setCustomBrandColor(null)
          }}
        />
      </section>

      <section className={styles.ux}>
        <Container>
          <Row className={styles.uxRow}>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Fade distance="10%" bottom>
                <Heading type="h4" bold color="exmoxPrimary" className={styles.heading}>
                  {content.uxTitle}
                  <span className={styles.dot}>.</span>
                </Heading>
              </Fade>

              <Fade delay={200} distance="10%" bottom>
                <Text color="dark" size="22" splitParagraphs>
                  {content.uxDescription.internal.content}
                </Text>
              </Fade>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.uxImages}>
              {isColumnLayout ? (
                <Fade distance="10%" delay={300} bottom key={content.uxImageMobile.fluid.src}>
                  <Img
                    fluid={content.uxImageMobile.fluid}
                    alt={content.uxImageMobile.description}
                    fadeIn={false}
                    style={{ position: 'relative' }}
                    imgStyle={{ objectFit: 'contain', position: 'relative' }}
                    className={styles.uxImagesImage}
                  />
                </Fade>
              ) : (
                content.uxImages.map((image, index) => (
                  <Fade distance="10%" delay={(index + 1) * 300} bottom key={image.fluid.src}>
                    <Parallax
                      style={{ overflow: 'visible' }}
                      renderLayer={percentage => (
                        <div
                          style={{
                            position: 'absolute',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '400px',
                            height: '400px',
                            overflow: 'visible',
                            ...getParallaxPercentage(index, percentage),
                          }}
                        >
                          <div style={{ height: '200px' }}>
                            <Img
                              fluid={image.fluid}
                              alt={image.description}
                              fadeIn={false}
                              style={{ position: 'absolute' }}
                              imgStyle={{ objectFit: 'contain', position: 'relative' }}
                              className={styles.uxImagesImage}
                              loading="eager"
                            />
                          </div>
                        </div>
                      )}
                    >
                      <div style={{ height: '200px' }} />
                    </Parallax>
                  </Fade>
                ))
              )}
            </Col>
          </Row>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-pale-blue'])
            setNavbarLight(true)
          }}
        />

        <ColorRibbon
          side="bottom"
          colors={[variables['exmox-primary']]}
          textColors={['white']}
          textSize="16"
        />
      </section>

      <section className={styles.visual}>
        <ColorRibbon
          side="top"
          colors={visualSectionColors}
          textColors={visualSectionTextColors}
          responsiveColumns={2}
          colorClass={styles.visualRibbonColor}
          textSize="16"
        />
        <Container>
          <div className={styles.visualRow}>
            <Fade distance="10%" bottom>
              <Heading type="h4" bold color="exmoxPrimary" className={styles.heading}>
                {content.visualTitle}
                <span className={styles.dot}>.</span>
              </Heading>
            </Fade>

            <Fade delay={200} distance="10%" bottom>
              <Text color="dark" size="22" splitParagraphs className={styles.description}>
                {content.visualDescription.internal.content}
              </Text>
            </Fade>
          </div>
        </Container>

        <div className={styles.visualImages}>
          {content.visualImages.map((image, index) => (
            <Fade distance="10%" delay={(index + 1) * 300} bottom key={image.fluid.src}>
              <Img
                fluid={image.fluid}
                alt={image.description}
                fadeIn={false}
                imgStyle={{ position: 'relative' }}
                className={styles.visualImagesImage}
              />
            </Fade>
          ))}
        </div>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-white-smoke'])
            setNavbarLight(true)
          }}
        />
      </section>

      <section className={styles.quests}>
        <div className={styles.questsImages}>
          {content.questsImages.map((image, index) => (
            <Fade distance="10%" delay={(index + 1) * 300} bottom key={image.fluid.src}>
              <Img
                fluid={image.fluid}
                alt={image.description}
                fadeIn={false}
                imgStyle={{ position: 'relative', objectFit: 'contain', objectPosition: 'left' }}
                className={styles.questsImagesImage}
              />
            </Fade>
          ))}
        </div>

        <Container>
          <div className={styles.questsRow}>
            <Fade distance="10%" bottom>
              <Heading type="h4" bold color="white" className={styles.heading}>
                {content.questsTitle}
                <span className={styles.dot}>.</span>
              </Heading>
            </Fade>

            <Fade delay={200} distance="10%" bottom>
              <Text color="white" size="22" splitParagraphs className={styles.description}>
                {content.questsDescription.internal.content}
              </Text>
            </Fade>
          </div>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['exmox-primary'])
            setNavbarLight(false)
          }}
        />
      </section>

      <section className={styles.powerUps}>
        <Container>
          <Row className={styles.powerUpsRow}>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.powerUpsContent}>
              <Fade distance="10%" bottom>
                <Heading type="h4" bold color="white" className={styles.heading}>
                  {content.powerUpsTitle}
                  <span className={styles.dot}>.</span>
                </Heading>
              </Fade>

              <Fade delay={200} distance="10%" bottom>
                <Text color="white" size="22" splitParagraphs>
                  {content.powerUpsDescription.internal.content}
                </Text>
              </Fade>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.powerUpsImages}>
              {content.powerUpsImages.map((image, index) => (
                <Fade distance="10%" delay={(index + 1) * 300} bottom key={image.fluid.src}>
                  <Img
                    fluid={image.fluid}
                    alt={image.description}
                    fadeIn={false}
                    imgStyle={{ position: 'relative' }}
                    className={styles.powerUpsImagesImage}
                  />
                </Fade>
              ))}
            </Col>
          </Row>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['exmox-primary-dark'])
            setNavbarLight(false)
          }}
        />
      </section>

      <section className={styles.shop}>
        <Container>
          <Row className={styles.shopRow}>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.shopImages}>
              {content.shopImages.map((image, index) => (
                <Fade distance="10%" delay={(index + 1) * 300} bottom key={image.fluid.src}>
                  <Img
                    fluid={image.fluid}
                    alt={image.description}
                    fadeIn={false}
                    imgStyle={{ position: 'relative' }}
                    className={styles.shopImagesImage}
                  />
                </Fade>
              ))}
            </Col>

            <Col xs={12} sm={12} md={6} lg={6} className={styles.shopContent}>
              <Fade distance="10%" bottom>
                <Heading type="h4" bold color="exmoxPrimary" className={styles.heading}>
                  {content.shopTitle}
                  <span className={styles.dot}>.</span>
                </Heading>
              </Fade>

              <Fade delay={200} distance="10%" bottom>
                <Text color="dark" size="22" splitParagraphs>
                  {content.shopDescription.internal.content}
                </Text>
              </Fade>
            </Col>
          </Row>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-white-smoke'])
            setNavbarLight(true)
          }}
        />
      </section>

      <section className={styles.portfolio}>
        <Fade distance="10%" bottom>
          <ProudCases currentProject="ExMox" caseStudies={content.proudCases} extendedCTA />
        </Fade>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-white'])
            setNavbarLight(true)
          }}
        />
      </section>
    </Layout>
  )
}

export const query = graphql`
  query contentfulExMoxCaseStudy($locale: String) {
    contentfulExMoxCaseStudy(fields: { localeKey: { eq: $locale } }) {
      pageTitle
      pageDescription
      pageThumbnail {
        file {
          url
        }
      }
      heroDescription {
        internal {
          content
        }
      }
      heroImages {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      challengeTitle
      challengeDescription {
        internal {
          content
        }
      }
      uxTitle
      uxDescription {
        internal {
          content
        }
      }
      uxImages {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      uxImageMobile {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      visualTitle
      visualDescription {
        internal {
          content
        }
      }
      visualImages {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      questsTitle
      questsDescription {
        internal {
          content
        }
      }
      questsImages {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      powerUpsTitle
      powerUpsDescription {
        internal {
          content
        }
      }
      powerUpsImages {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      shopTitle
      shopDescription {
        internal {
          content
        }
      }
      shopImages {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      proudCases {
        id
        title
        main
        platforms {
          internal {
            content
          }
        }
        projectColor
        projectName
        thumbnail {
          description
          fixed {
            ...GatsbyContentfulFixed_withWebp_noBase64
          }
          file {
            details {
              image {
                width
                height
              }
            }
          }
        }
      }
    }
  }
`

ExMoxCaseStudy.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
}

export default ExMoxCaseStudy
